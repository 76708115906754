import React from 'react';

const Header = () => {
    return (
        <header>
            <div className="container">
                <p><b>КРУПНЕЙШИЙ</b> СКЛАД одноразовой посуды и упаковки в Казахстане</p>
                <p>Сэкономьте Ваше время на поиски товара! <a href="/pricelist">Скачайте наш прайс-лист</a></p>
                
            </div>
        </header>
    );
}

export default Header;
