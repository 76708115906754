import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ShopStore from "./store/shop-store";
import AdminStore from "./store/admin-store";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import "@fancyapps/ui/dist/fancybox.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
export function numberWithSpaces(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
export function getMinutesDifference(date) {
    const diffInMs = new Date() - date;
    return Math.floor((diffInMs / 1000) / 60);
  }

export const Context = createContext(null)

root.render(
    <Context.Provider value={{
        shopStore: new ShopStore(),
        adminStore:new AdminStore()
    }}>
        <App/>
    </Context.Provider>
);
