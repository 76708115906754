import React from "react";
import { calculateTotal, formatPrice } from "../helpers/price";

const SetGoods = ({
  goods,
  good,
  visibleProducts,
  shopStore,
  numberWithSpaces,
  toggleShowA,
  handleShowLess,
  handleShowMore,
}) => {
  

  return (
    <div>
      {goods?.length > 0 && good && (
        <>
          <div className="some-goods">
            <h4>Комплект</h4>
          </div>
          <div>
            {goods.slice(0, visibleProducts)?.map((item, index) => (
              <div className="card-releated-row">
                <div className="card-releated-block">
                  <div className="card-releated-block-img">
                    <a href={"/catalog/good/" + good.article}>
                      <img
                        src={
                          good?.images?.filter((img) =>
                            img?.path.includes("_min")
                          )[0]?.path
                        }
                        alt={good?.name}
                      />
                    </a>
                  </div>
                  <div className="card-releated-block-info">
                    <div className="card-releated-block-info-text">
                      <a
                        className="card-link"
                        href={"/catalog/good/" + good.article}
                      >
                        {good?.name}
                      </a>
                    </div>
                    <div className="card-releated-block-price">
                      <span className="card-releated-block-price-t">
                        {formatPrice(good)}{" "}
                        ₸
                      </span>
                      <span className="card-releated-block-price-p">
                        {good?.singlePrice}₸/шт
                      </span>
                    </div>
                  </div>
                </div>
                <p className="card-releated-block-plus">+</p>
                <div className="card-releated-block">
                  <div className="card-releated-block-img">
                    <a
                      className="card-link"
                      href={"/catalog/good/" + item.article}
                    >
                      <img
                        src={
                          item.images.filter((img) =>
                            img?.path.includes("_min")
                          )[0]?.path
                        }
                        alt={item?.name}
                      />
                    </a>
                  </div>
                  <div className="card-releated-block-info">
                    <div className="card-releated-block-info-text">
                      <a
                        className="card-link"
                        href={"/catalog/good/" + item.article}
                      >
                        {" "}
                        {item?.name}
                      </a>
                    </div>
                    <div className="card-releated-block-price">
                      <span className="card-releated-block-price-t">
                          {formatPrice(item)}{" "}
                        ₸
                      </span>
                      <span className="card-releated-block-price-p">
                        {item?.singlePrice}₸/шт
                      </span>
                    </div>
                  </div>
                </div>
                <p className="card-releated-block-total">=</p>
                <div className="card-releated-block-action">
                  <span className="action-price">
                    {calculateTotal(item ,good)}{" "}
                    ₸
                  </span>
                  {shopStore.inBasket(item.id) ? (
                    <a href="/basket" className="action-button">
                      Перейти в корзину{" "}
                    </a>
                  ) : (
                    <button
                      onClick={() => {
                        if (good?.isActive) {
                          !shopStore.inBasket(good.id) &&
                            shopStore.setBasket(good);
                          shopStore.setBasket(item);
                          toggleShowA();
                        }
                      }}
                      className="action-button"
                    >
                      В корзину комплектом{" "}
                    </button>
                  )}
                </div>
              </div>
            ))}
            <div className="show-block">
              {visibleProducts < goods.length ? (
                <button className="show-btn" onClick={handleShowMore}>
                  Показать все ({goods?.length})
                </button>
              ) : (
                goods?.length > 3 && (
                  <button className="show-btn" onClick={handleShowLess}>
                    Скрыть все
                  </button>
                )
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SetGoods;
