import { observer } from "mobx-react-lite";
import React from "react";
import { useContext } from "react";
import { Context } from "..";
import { ReactComponent as Logo } from "../assets/icons/ddw.svg";
const Footer = () => {
  const { shopStore } = useContext(Context);

  return (
    <footer>
      <div className="container">
        {/* <div className="subscribe-form">
                <h4 className="mb-4">Получайте новости о распродажах, скидках и новых поступлениях первым</h4>
                <div className="d-flex justify-content-center align-items-center">
                    <input type="text" value="" placeholder="ваш e-mail адрес"/>
                    <button className="btn btn-outline-light rounded-0 btn-lg">Подписаться</button>
                </div>
            </div> */}
        <div className="row">
          <div className="col-md-6">
            <h4>Помощь клиентам</h4>
            <div className="row">
              <div className="col-md-6">
                <ul>
                  <li>
                    <a href="/return">Возврат и обмен товара</a>
                  </li>
                  <li>
                    <a href="/delivery">Информация о доставке</a>
                  </li>
                  <li>
                    <a href="/payment">Условия оплаты заказов</a>
                  </li>
                  <li>
                    <a href="/privacy">Политика безопасности</a>
                  </li>
                  <li>
                    <a href="/publicOffer">Публичный договор-оферта</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul>
                  {/* <li className="f-important"><a href="/sale">Распродажа</a></li>
                                <li><a href="/news">Новости</a></li> */}
                  <li>
                    <a href="/sitemap">Карта сайта</a>
                  </li>
                  <li>
                    <a href="/about">О нас</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row">
              <div className="col-md-6">
                <h4>Как связаться с нами?</h4>
                <ul className="mt-3">
                  {shopStore.phones.map((m, index) => {
                    return (
                      <li key={index}>
                        <a href={"tel:+" + m.replace(/\D/g, "")}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-telephone-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                            />
                          </svg>
                          {m}
                        </a>
                      </li>
                    );
                  })}

                  <li>
                    <div className="s-line-light"></div>
                  </li>
                  <li>
                    <a href="/contacts">Все контакты</a>
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <h4>Мы в соц сетях</h4>
                <div className="social">
                  <a
                    target="__blank"
                    href="https://www.instagram.com/upack.kz/"
                  >
                    <img
                      src="https://img.icons8.com/color/48/instagram-new--v1.png"
                      alt=""
                    />
                  </a>
                  <a target="__blank" href="https://www.facebook.com/upack.kz">
                    <img
                      src="https://img.icons8.com/fluency-systems-regular/48/ffffff/facebook-new--v1.png"
                      alt="facebook-new--v1"
                    />
                  </a>
                  {/* <a href="">
                    <img src="/Images/icons/telegram.svg" alt="" />
                  </a>
                  <a href="">
                    <img src="/Images/icons/whatsapp.svg" alt="" />
                  </a> */}
                </div>
                <h5 className="mt-4">UPACK.KZ</h5>
                <span className="ddw-logo">
                  <Logo />
                </span>
              </div>
            </div>
          </div>
        </div>

        <p className="text-center mt-4 mb-0" style={{ fontSize: 14 }}>
          COPYRIGHT © {new Date().getFullYear()} UPACK ® ВСЕ ПРАВА ЗАЩИЩЕНЫ
        </p>
      </div>
    </footer>
  );
};

export default observer(Footer);
