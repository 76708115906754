import React from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { useContext } from 'react';
import { Context } from '../..';

const Contacts = () => {

    const {shopStore} = useContext(Context)

    return (
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-6">
            <h4>Адрес склада</h4>
            <p>г. Алматы, ул. Яссауи, дом 1Б</p>
            <h4>Единый Call-center</h4>
            {shopStore.phones.map((m, index) => {
              return (
                <a key={index} href={"tel:+" + m.replace(/\D/g, "")}>
                  <p className="mb-1">{m}</p>
                </a>
              );
            })}
            <h4>Время работы интернет-магазина</h4>
            <p className="mb-1">Пн-Пт 9:00 - 18:00</p>
            <p className="mb-1">Сб 9:00 - 15:00</p>
            <p>Вс выходной</p>
            <h4>Наш партнер: Магазин "PARTY"</h4>
            <p className="mb-1">г. Алматы, ул. Желтоксан, 5Б к1</p>
            <p className="mb-1">Пн-Сб 10:00 - 19:00</p>
            <p>Вс 10:00 - 17:00</p>
          </div>
          {/* <div className="col-md-6" id="map">
            <YMaps id="contacts-map">
              <Map
                defaultState={{
                  center: [43.201119, 76.838874],
                  zoom: 16,
                }}
                height={500}
                style={{
                  borderRadius: 10,
                  border: "1px solid #d5d7ff",
                  overflow: "hidden",
                  width: "100%",
                  height: 500,
                }}
              >
                <Placemark geometry={[43.201119, 76.838874]} />
              </Map>
            </YMaps>
          </div> */}
        </div>
      </div>
    );
}

export default Contacts;
