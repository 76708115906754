/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { fetchGood, fetchRelatedGoods } from "../../http/shop-api";
import { Thumbs } from "swiper";
import { Context, numberWithSpaces } from "../../index";
import { observer } from "mobx-react-lite";
import SomeGoodList from "../../components/some-good-list";
import SomeCategoryList from "../../components/some-category-list";
import { Toast } from "react-bootstrap";
import SetGoods from "../../components/set-goods";
import { formatPrice } from "../../helpers/price";

const GoodInfo = () => {
  const { shopStore } = useContext(Context);
  const { article } = useParams();
  const [good, setGood] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const [visibleProducts, setVisibleProducts] = useState(3);

  const [category, setCategory] = useState({ id: 0, name: "" });

  const [showA, setShowA] = useState(false);

  const [goods, setGoods] = useState();

  const toggleShowA = () => {
    setShowA(!showA);
    setTimeout(() => {
      setShowA(false);
    }, 5000);
  };

  const handleShowMore = () => {
    // Увеличиваем количество видимых товаров на 3
    setVisibleProducts((prevVisible) => prevVisible + goods?.length);
  };

  const handleShowLess = () => {
    setVisibleProducts(3);
  };
  useEffect(() => {
    fetchGood(article).then((data) => {
      data.images.sort((a, b) => {
        if (a.path.includes("dop") && !b.path.includes("dop")) {
          return 1;
        }
        if (!a.path.includes("dop") && b.path.includes("dop")) {
          return -1;
        }
        return 0;
      });
      setGood(data);
      for (let general_section of shopStore.menu) {
        for (let section of general_section.sections) {
          for (let cat of section.categories) {
            if (cat.id == data.categoryId) {
              setCategory({ id: cat.id, name: cat.name });
            }
          }
        }
      }
      document.querySelector(".catalog-good-info-callback-b24").innerHTML =
        document.getElementById("catalog-good-info-callback-b24")?.innerHTML;

      const size_fields = ["Длина, мм", "Ширина, мм", "Высота, мм"];
      let html = "",
        mob_html = "",
        size_html = "";
      for (let detail in data.details) {
        if (data.details[detail] !== "не указан") {
          if (size_fields.includes(detail)) {
            size_html += `<tr><td><p class="text-truncate">${
              detail + ".".repeat(100)
            }</p></td><td>${data.details[detail]}</td></tr>`;
          } else {
            html += `<tr><td><p class="text-truncate">${
              detail + ".".repeat(100)
            }</p></td><td>${data.details[detail]}</td></tr>`;
            mob_html += `<p><b>${detail}</b><br/>${data.details[detail]}</p>`;
          }
        }
      }
      document.querySelector("#details-table tbody").innerHTML = html;
      document.querySelector("#details-table-mob").innerHTML = mob_html;
      document.querySelector("#details-table-size tbody").innerHTML = size_html;
    });
  }, []);

  useEffect(() => {
    fetchRelatedGoods(article).then((data) => setGoods(data));
    console.log(goods);
  }, [article]);
  return (
    <>
      <Toast id="added-to-basket" show={showA} onClose={toggleShowA}>
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Корзина</strong>
        </Toast.Header>
        <Toast.Body>
          Товар с артикулом {good?.article} добавлен в корзину!
        </Toast.Body>
      </Toast>

      <div
        className={"container"}
        id={"good-info"}
        itemscope
        itemtype="http://schema.org/Product"
      >
        <div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Главная</a>
            </li>
            <li className="breadcrumb-item">
              <a href={"/catalog/category/" + category.id}>{category.name}</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {good?.name || ""}
            </li>
          </ol>
        </div>
        <div className="bg-white shadow-sm" style={{ minHeight: 560 }}>
          <div className="row">
            <div className={"col-md-4"}>
              {good ? (
                <>
                  <Swiper
                    modules={[Thumbs]}
                    thumbs={{ swiper: thumbsSwiper }}
                    id={"big-slider"}
                    spaceBetween={50}
                    slidesPerView={1}
                    autoplay={true}
                  >
                    {good.images
                      .filter((m) => m.path.includes("_min"))
                      .map((m) => (
                        <SwiperSlide key={m.id}>
                          <img
                            src={m.path.replace("_min", "")}
                            data-fancybox="gallery"
                            itemprop="image"
                            href={m.path.replace("_min", "")}
                            style={{
                              width: "100%",
                              aspectRatio: "1 / 1",
                              objectFit: "cover",
                            }}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                  <Swiper
                    id={"small-slider"}
                    spaceBetween={10}
                    slidesPerView={"auto"}
                    modules={[Thumbs]}
                    watchSlidesProgress
                    onSwiper={setThumbsSwiper}
                  >
                    {good.images
                      .filter((m) => m.path.includes("_min"))
                      .map((m) => (
                        <SwiperSlide key={m.id}>
                          <div
                            className="item"
                            style={{ backgroundImage: `url('${m.path}')` }}
                          ></div>
                        </SwiperSlide>
                      ))}
                  </Swiper>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="col-md-8">
              <h1 itemprop="name">{good && good.name}</h1>
              <div className="row good-info-row">
                <div
                  className="col-md-6"
                  itemprop="offers"
                  itemtype="http://schema.org/Offer"
                >
                  <meta itemprop="priceCurrency" content="KZT" />
                  {good ? (
                    <>
                      <p className="article">Артикул: {good.article}</p>
                      {good.isActive ? (
                        <>
                          <div className="prices">
                            <p className="sale-price">
                              <span
                                itemprop="price"
                                content={formatPrice(good)}
                              >
                                {formatPrice(good)}
                              </span>{" "}
                              <span>тг/коробка</span>
                            </p>
                            <p className={"single-price"}>
                              {numberWithSpaces(good?.singlePrice?.toFixed(2))}
                              <span>тг/{good?.dimension}</span>
                            </p>
                          </div>
                          <p className="attention">
                            В коробке{" "}
                            {good.isBox === 1
                              ? good?.minOpt * good?.inBox
                              : good?.minOpt * good?.inPack}{" "}
                            {good?.dimension}
                          </p>
                        </>
                      ) : (
                        <>
                          <div className="prices">
                            <p className="sale-price">
                              <span itemprop="price">Нету в наличии</span>{" "}
                              {/* <span>тг/коробка</span> */}
                            </p>
                          </div>
                        </>
                      )}

                      {shopStore.inBasket(good.id) ? (
                        <a
                          style={
                            good?.isActive
                              ? { display: "block" }
                              : { display: "none" }
                          }
                          href="/basket"
                          className={"btn btn-success btn-lg"}
                        >
                          Перейти к корзине
                        </a>
                      ) : (
                        <button
                          style={
                            good?.isActive
                              ? { display: "block" }
                              : { display: "none" }
                          }
                          className={"btn btn-primary btn-lg"}
                          onClick={() => {
                            if (good?.isActive) {
                              shopStore.setBasket(good);
                              toggleShowA();
                            }
                          }}
                        >
                          Добавить в корзину
                        </button>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                  <p className={"details-header fw-bold mt-4 mb-3"}>
                    Характеристики
                  </p>
                  <table className={"details-table"} id="details-table">
                    <tbody></tbody>
                  </table>

                  <p className={"details-header fw-bold mt-4 mb-3"}>
                    Размеры транспортной коробки
                  </p>
                  <table className="details-table" id="details-table-size">
                    <tbody></tbody>
                  </table>

                  <div
                    className="details-table-mob"
                    id="details-table-mob"
                  ></div>
                </div>
                <div className="col-md-6 pe-3">
                  <div className={"catalog-good-info-callback-b24"}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Комплект товаров */}
        <SetGoods
          good={good}
          goods={goods}
          shopStore={shopStore}
          numberWithSpaces={numberWithSpaces}
          visibleProducts={visibleProducts}
          handleShowLess={handleShowLess}
          handleShowMore={handleShowMore}
          toggleShowA={toggleShowA}
        />
        {/* Комплект товаров */}
        {/* <SomeGoodList
          title={"Сопутствующие товары"}
          article={article}
          type={"related"}
        /> */}
        <div className="bg-white shadow-sm">
          <h4>Описание</h4>
          {good && good.description.length > 4 ? (
            <div
              className={"description"}
              itemprop="description"
              dangerouslySetInnerHTML={{ __html: good.description }}
            ></div>
          ) : (
            <div className={"description"} itemprop="description">
              <p>
                Если Вы видите этот текст вместо описания товара, значит наши
                программисты что-то напутали, и мы это уже исправляем. <br />
                Однако!
                <br />
                Несмотря на это, если Вы видите товар, его фото и цену в нашем
                интернет-магазине, то можете смело рассчитывать на то, что он
                есть в наличии. Оформить заказ Вы сможете через корзину либо
                позвонив в наш отдел продаж по одному из номеров, указанных в
                правом верхнем углу. Приносим извинения за временные неудобства.
                <br />
                <br />
                А пока мы разбираемся, Вы можете ознакомиться с краткой
                информацией о нашей компании:
                <br />
                UPACK.KZ предлагает Вам широкий ассортимент товаров для HoReCa и
                упаковочных материалов высокого качества по доступным ценам. Мы
                осуществляем доставку по всему Казахстану.
                <br />
                <br />
                Мы за экологию:
                <br />
                При соблюдении правил использования и утилизации упаковка из
                полипропилена для пищевых продуктов не причиняет вреда
                окружающей среде и здоровью человека.
              </p>
            </div>
          )}
        </div>
        <SomeGoodList title={"Похожие "} article={article} type={""} />
        <SomeCategoryList
          type={"popular"}
          title={"Популярные категории товаров"}
        />
        <SomeGoodList title={"Хиты продаж"} article={"123"} type={"hits"} />
        <SomeGoodList title={"Новинки"} article={"123"} type={"new"} />
      </div>
    </>
  );
};

export default observer(GoodInfo);
