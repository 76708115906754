import React, { useContext, useState } from 'react';
import { fetchFinder } from "../http/shop-api";
import { Context } from "../index";
import { observer } from "mobx-react-lite";
import FinderGoodItem from "./finder-good-item";
import { useNavigate } from 'react-router-dom';

const Finder = () => {

    const [value, setValue] = useState('')
    const { shopStore } = useContext(Context)

    const navigate = useNavigate()

    const handleFinder = (query) => {
        setValue(query)
        if (query.length > 2) {
            document.getElementById('finder-button').innerHTML = `<Spinner animation="border" role="status">
                    <span className="visually-hidden">Поиск...</span>
                </Spinner>`


            fetchFinder(query).then((data) => {
                shopStore.setFinderGoods(data.goods)
                shopStore.setFinderCategories(data.categories)
                document.getElementById('finder-button').innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            className="bi bi-search" viewBox="0 0 16 16">
                        <path
                            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                    </svg>`
            })
        } else {
            shopStore.setFinderGoods(null)
            shopStore.setFinderCategories(null)
        }
    }

    document.addEventListener('click', (e) => {
        let finderBlock = document.querySelector('.finder')
        let target = e.target
        if (!finderBlock.contains(target)) {
            handleFinder('')
        }
    })

    // получаем элемент поля ввода
    const inputField = document.querySelector('#search-input');

    if (inputField) {
        // добавляем обработчик события 'keydown'
        inputField.addEventListener('keydown', function (event) {
            // проверяем, что нажата клавиша Enter (код клавиши равен 13)
            if (event.keyCode === 13) {
                window.location.href='/finder/'+value
            }
        });
    }




    return (
        <div className={'position-relative flex-grow-1'} style={{ height: 40 }}>
            <div className={'finder ' + (value.length > 0 ? 'shadow' : '')}>
                <div className={'form'}>
                    <input type="text"
                        value={value}
                        id="search-input"
                        onChange={(e) => handleFinder(e.target.value)}
                        placeholder={'поиск по сайту...'}
                    />
                    <a id={'finder-button'} href={'/finder/' + value}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            className="bi bi-search" viewBox="0 0 16 16">
                            <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                    </a>
                </div>


                {
                    shopStore.finderGoods != null || shopStore.finderCategories != null ?
                        <div className={'result'}>
                            {
                                shopStore.finderCategories != null ?
                                    <>
                                        <h6 className='mb-3'>Категории</h6>
                                        <ul className={'mb-3'} style={(shopStore.finderGoods != null ? {
                                            borderBottom: '1px solid #2B377AFF',
                                            paddingBottom: 20
                                        } : {})}>
                                            {
                                                shopStore.finderCategories.map(m =>
                                                    <li key={m.id}>
                                                        <a href={"/catalog/category/" + m.id} className={'category-button'}>{m.name}</a>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </> : <></>
                            }
                            {
                                shopStore.finderGoods != null ?
                                    <>
                                        <h6>Товары</h6>
                                        <ul>
                                            {
                                                shopStore.finderGoods.map(m =>
                                                    <li key={m.id}>
                                                        <FinderGoodItem good={m} />
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </> : <></>
                            }

                        </div>
                        :
                        <></>
                }
            </div>
        </div>
    );
};

export default observer(Finder);