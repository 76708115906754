import {makeAutoObservable} from "mobx";

export default  class AdminStore{
    constructor() {
        this._isAuth = false
        makeAutoObservable(this)
    }

    setAuth(data){
        this._isAuth = true
        localStorage.setItem('token',data.access_token)
    }
    get isAuth(){
        return this._isAuth
    }
}