import React, { useContext } from 'react';
import { Outlet } from "react-router-dom";
import LoginForm from "../../components/login-form";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import { Container } from 'react-bootstrap';

import '../../css/private.css'

const AdminLayout = () => {
    const { adminStore } = useContext(Context)
    return (
        <div className='admin-root'>
            {
                adminStore.isAuth || true ?
                    <>
                        <aside>

                        </aside>
                        <main>
                            <Container>
                                <Outlet />
                            </Container>
                        </main>
                    </>
                    :
                    <LoginForm />
            }
        </div>
    );
};

export default observer(AdminLayout);