import React from "react";
import { numberWithSpaces } from "../index";
import { formatPrice } from "../helpers/price";

const GoodListCard = ({ good }) => {
  return (
    <a href={"/catalog/good/" + good.article} className="text-decoration-none">
      <div className={"good-list-card"}>
        <div
          className="img"
          style={{
            backgroundImage: `url('${
              good.images.filter((img) => img?.path.includes("_min"))[0]?.path
            }')`,
          }}
        ></div>
        <small>Арт: {good.article}</small>
        <p>{good.name}</p>
        <h5>
          {formatPrice(good)}{" "}
          тг/ {good.inBox == 1 ? good.dimension : "кор"}
        </h5>
      </div>
    </a>
  );
};

export default GoodListCard;
