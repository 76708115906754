export class ImageModel {
    constructor(data) {
        if (data) {
            this.id = data.id
            this.goodId = data.goodId
            this.path = data.path
        } else {
            this.id = 0
            this.goodId = 0
            this.path = '/logo192.png'
        }

    }

    static getAll(data) {
        let list = []
        for (let item of data) {
            list.push(new ImageModel(item))
        }
        return list
    }
}