import { fetchFinder, fetchHits, fetchSetHit } from '../../http/shop-api';
import { GoodModel } from '../../models/good-model';
import React, { useState, useEffect } from 'react';
import { Form, FormControl, ListGroup, Button } from 'react-bootstrap';



const ManageHits = () => {

    const [goods, setGoods] = useState([]);
    const [filteredgoods, setFilteredgoods] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        fetchHits().then((data) => {
            setGoods(GoodModel.getAll(data))
        })
    }, [])

    const handleSearch = e => {
        setSearchTerm(e.target.value);
        if(e.target.value.length>2) {
            fetchFinder(searchTerm)
            .then(res => setFilteredgoods(GoodModel.getAll(res.goods)))
            .catch(err => console.error(err));
        }else {
            setFilteredgoods([])
        }
    };

    const handleAdd = product => {
        setHit(product,true)
        setFilteredgoods([])
        setSearchTerm('')
        setGoods([...goods, product]);
    };

    const handleRemove = product => {
      
        setHit(product,false)
        setGoods(goods.filter(m=>m.id!=product.id))
    }

    const setHit = (good, status) => {
        fetchSetHit(good.id, status).then((data) => {
        })
    }





    return (
        <div>
            <h3 className='mb-3'>Настройка для выкладки товаров "Хиты продаж"</h3>

            <Form>
                <FormControl
                    type="text"
                    placeholder="Search by name or article"
                    onChange={handleSearch}
                />
            </Form>
            <ListGroup style={{maxHeight:300,overflowX:'auto'}}>
                {filteredgoods.map(product => (
                    <ListGroup.Item key={product.id} className='d-flex' style={{gap:20}}>
                        <Button onClick={() => handleAdd(product)}>Добавить</Button>
                        {product.name} ({product.article})
                    </ListGroup.Item>
                ))}
            </ListGroup>



            <table className='table table-bordered mt-5'>
                <thead>
                    <tr>
                        <th>
                            Картинка
                        </th>
                        <th>
                            Наименование
                        </th>
                        <th>
                            Действия
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        goods.map((m) => {
                            return <tr key={m.id}>
                                <td width={100} className='p-2'>
                                    <img src={m.images[0].path} width={80} />
                                </td>
                                <td>
                                    <div>
                                        <small>{m.article}</small>
                                        <p className='mb-0'>{m.name}</p>
                                    </div>
                                </td>
                                <td>
                                    {
                                        goods.length>5 ?
                                        <button className='btn btn-danger btn-sm' onClick={() => handleRemove(m)}>Удалить</button>
                                        :<></>
                                    }
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default ManageHits;
