import {observer} from "mobx-react-lite";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Layout from "./pages/layouts/layout";
import AdminLayout from "./pages/layouts/admin-layout";
import HomeIndex from "./pages/home/home-index";
import CatalogCategory from "./pages/catalog/catalog-category";
import GoodInfo from "./pages/catalog/good-info";
import Basket from "./pages/home/basket";
import Thanks from "./pages/home/thanks";
import Return from "./pages/home/return";
import Delivery from "./pages/home/delivery";
import Payment from "./pages/home/payment";
import Privacy from "./pages/home/privacy";
import PublicOffer from "./pages/home/public-offer";
import AboutUs from "./pages/home/about-us";
import Contacts from "./pages/home/contacts";
import PriceList from "./pages/catalog/price-list";
import { useContext, useEffect } from "react";
import { Context } from ".";
import { fetchMenu } from "./http/shop-api";
import ManageHits from "./pages/private/manage-hits";
import ManagePopularCategory from "./pages/private/manage-popular-category";
import Finder from "./pages/home/finder";

function App() {

  const {shopStore} = useContext(Context)

  useEffect(() => {
    // if(shopStore?.menu[1]?.sections?.length === 2 || shopStore.menu?.length === 0){
          fetchMenu().then((data)=>{
              shopStore.setMenu(data)
          })
    // }
  },[])

  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<Layout/>}>
          <Route index element={<HomeIndex/>}/>
          <Route path={'/catalog/category/:id'} element={<CatalogCategory/>}/>
          <Route path={'/catalog/good/:article'} element={<GoodInfo/>}/>
          <Route path={'/basket'} element={<Basket/>}/>
          <Route path={'/thanks'} element={<Thanks/>}/>
          <Route path={'/basket/thanks'} element={<Thanks/>}/>
          <Route path={'/return'} element={<Return/>}/>
          <Route path={'/delivery'} element={<Delivery/>}/>
          <Route path={'/payment'} element={<Payment/>}/>
          <Route path={'/privacy'} element={<Privacy/>}/>
          <Route path={'/publicOffer'} element={<PublicOffer/>}/>
          <Route path={'/about'} element={<AboutUs/>}/>
          <Route path={'/contacts'} element={<Contacts/>}/>
          <Route path={'/pricelist'} element={<PriceList/>}/>
          <Route path={'/finder/:query'} element={<Finder/>}/>
        </Route>
        <Route path={'/uadmin'} element={<AdminLayout/>}>
          <Route path="hits" element={<ManageHits/>} />
          <Route path="popularcategories" element={<ManagePopularCategory/>}/>
        </Route>
        <Route path={'*'} element={<Navigate to="/"/>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default observer(App);
