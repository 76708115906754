import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import {
  fetchCities,
  fetchDeliveryPrice,
  fetchMakeOrder,
} from "../http/shop-api";
import { Context, numberWithSpaces } from "../index";

const MakeOrder = () => {
  const { shopStore } = useContext(Context);

  const [phone, setPhone] = useState("+7");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [cities, setCities] = useState([]);
  const [company, setCompany] = useState("");
  const [cityId, setCityId] = useState(1);

  const [saleTypeId, setSaleTypeId] = useState(1);
  const [payTypeId, setPayTypeId] = useState(1);

  const [deliveryPrice, setDeliveryPrice] = useState(0);

  const handleForm = (e) => {
    e.preventDefault();
    let p = phone.replace(/\D/g, "");
    if (p.length != 11) {
      alert("Некорректный номер телефона");
      return false;
    }
    let goods = shopStore.basket.map((m) => {
      return {
        amount: m.amount,
        id: m.good.id,
        price: Math.round(
          m.good.isBox === 1
            ? m.good.inBox * m.good.minOpt * m.good.singlePrice
            : m.good.inPack * m.good.minOpt * m.good.singlePrice
        ),
      };
    });

    let order = {
      goods,
      saleTypeId,
      payTypeId,
      email,
      phone: phone.replaceAll(" ", ""),
      firstName,
      secondName,
      company,
      cityId: +cityId,
    };

    document.querySelector(".submit-block button").innerHTML = `
            <div class="spinner-border" style="width: 1rem; height: 1rem;"  role="status"> </div> отправка
        `;
    document
      .querySelector(".submit-block button")
      .setAttribute("disabled", "disabled");

    fetchMakeOrder(order).then((data) => {
      if (data.status == 1) {
        order.id = data.orderId;
        order.totalSum = shopStore.totalSum();
        order.cities = cities;

        shopStore.setCurrentOrder(order);

        if (payTypeId == 2) {
          window.location.href = data.paymentLink;
        } else {
          window.location.href = "/thanks";
        }
      } else {
        alert(
          "Что-то пошло не так попробуйте. Попробуйте оформить заказ через несколько минут или обратитесь в службу поддержки"
        );
        document.querySelector(
          ".submit-block button"
        ).innerHTML = `Оформить заказ`;
        document
          .querySelector(".submit-block button")
          .removeAttribute("disabled");
      }
    });
  };

  const changeSaleType = (value) => {
    setSaleTypeId(value);
  };

  useEffect(() => {
    fetchCities().then((data) => setCities(data));
    fetchDeliveryPrice().then((data) => setDeliveryPrice(data));
  }, []);

  return (
    <div className="make-order-block shadow-sm">
      <h4 className={"mb-3"}>Оформление заказа</h4>
      <form onSubmit={(e) => handleForm(e)}>
        <div className="row">
          <div className="col-md-6">
            <div className="step">
              <div className="circle">1</div>
              <h6>Данные клиента</h6>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name={"email"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                    placeholder="name@example.com"
                  />
                  <label htmlFor="email">
                    <span>*</span>Email
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <InputMask
                    mask="+7 799 999 99 99"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="form-control"
                    id="phone"
                    name={"phone"}
                    required={true}
                  />
                  <label htmlFor="phone">
                    <span>*</span>Телефон
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    name={"first_name"}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder="name@example.com"
                    required={true}
                  />
                  <label htmlFor="first_name">
                    <span>*</span>Имя
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="second_name"
                    name={"second_name"}
                    value={secondName}
                    onChange={(e) => setSecondName(e.target.value)}
                    placeholder="name@example.com"
                  />
                  <label htmlFor="second_name">Фамилия</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="company"
                    name={"company"}
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    placeholder="name@example.com"
                  />
                  <label htmlFor="company">Компания</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating">
                  <select
                    className="form-select"
                    id="floatingSelect"
                    value={cityId}
                    onChange={(e) => setCityId(e.target.value)}
                    aria-label="Floating label select example"
                  >
                    {cities.map((m) => (
                      <option key={m.id} value={m.id}>
                        {m.name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="floatingSelect">Город</label>
                </div>
              </div>
            </div>
            <small>
              <span>*</span> обязательные поля
            </small>
          </div>
          <div className="col-md-6">
            <div className="step">
              <div className="circle">2</div>
              <h6>Доставка и оплата</h6>
            </div>
            <p>Выберите предпочтительный способ доставки</p>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="1"
                onChange={() => changeSaleType(1)}
                name="SaleTypeId"
                defaultChecked={true}
              />
              <label
                className="form-check-label"
                onClick={() => changeSaleType(1)}
              >
                Самовывоз
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="2"
                onChange={() => changeSaleType(2)}
                name="SaleTypeId"
              />
              <label
                className="form-check-label"
                onClick={() => changeSaleType(2)}
              >
                Курьерская доставка по Алматы
              </label>
              <small>
                * При заказе от 35 000 тг доставка осуществляется по городу
                Алматы БЕСПЛАТНО
              </small>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                value="3"
                onChange={() => changeSaleType(3)}
                name="SaleTypeId"
              />
              <label
                className="form-check-label"
                onClick={() => changeSaleType(3)}
              >
                Доставка по Казахстану
              </label>
              <small>
                * Доставка за пределы города Алматы рассчитывается индивидуально
              </small>
            </div>
            <p className={"mt-4"}>Выберите предпочтительный способ оплаты</p>
            <div className="d-flex" style={{ gap: 30 }}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  value="1"
                  name="payTypeId"
                  onChange={() => setPayTypeId(1)}
                  defaultChecked={true}
                />
                <label
                  className="form-check-label"
                  onClick={() => setPayTypeId(1)}
                >
                  Оплата при получении
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  value="2"
                  onChange={() => setPayTypeId(2)}
                  name="payTypeId"
                />
                <label
                  className="form-check-label"
                  onClick={() => setPayTypeId(2)}
                >
                  Онлайн оплата
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="submit-block">
          <div>
            <h4>
              Итого: <span>{numberWithSpaces(shopStore.totalSum())}</span> тг
              {saleTypeId === 2 && shopStore.totalSum() < 35000 ? (
                <span>&nbsp;+ {numberWithSpaces(2800)} тг (доставка)</span>
              ) : saleTypeId === 3 ? (
                <span>&nbsp;+ доставка (индивидуально)</span>
              ) : saleTypeId === 2 && shopStore.totalSum() > 35000 ? (
                <span>&nbsp;+ бесплатная доставка</span>
              ) : null}
            </h4>
            {saleTypeId != 1 ? (
              <>
                <small>Доставка оплачивается отдельно</small>
              </>
            ) : (
              <></>
            )}
          </div>

          <button type={"submit"} className={"btn btn-primary btn-lg mt-3"}>
            Оформить заказ
          </button>
        </div>
      </form>
    </div>
  );
};

export default observer(MakeOrder);
