import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import {fetchCategories, fetchCategory} from "../../http/shop-api";
import {Context} from "../../index";
import CatalogCategoryGoodList from "../../components/catalog-category-good-list";

const CatalogCategory = () => {
    const {id} = useParams()

    const {shopStore} = useContext(Context)
    const [categoryName, setCategoryName] = useState('загрузка...')
    const [sectionName, setSectionName] = useState('загрузка...')
    const [categories, setCategories] = useState([])

    useEffect(() => {
        fetchCategory(id).then((data) => {
                setCategoryName(data.name)
                setSectionName(data.sectionName)
                fetchCategories(data.sectionId).then(data_categories =>
                    setCategories(data_categories)
                )
            }
        )

    }, [])

    return (
        <div className={'container mt-3 in-category'}>
            <h2 className={'mb-4'}><span className={'text-muted'}>{sectionName}</span> / {categoryName}</h2>

            <div className="row">
                <div className="col-md-3 mb-3">
                    <div className="left-block shadow-sm">
                        <h5>Другие категории раздела</h5>
                        <ul>
                            {
                                categories.map(m =>
                                    <li key={m.id}><a href={'/catalog/category/' + m.id}>{m.name}</a></li>
                                )
                            }
                        </ul>
                    </div>
                </div>
                <div className="col-md-9">
                    <CatalogCategoryGoodList id={id}/>
                </div>
            </div>
        </div>
    );
};

export default observer(CatalogCategory);