/* eslint-disable no-undef */
import React from 'react';
import {observer} from "mobx-react-lite";
import HomeIndexBanner from "../../components/home-index-banner";
import {Container} from "react-bootstrap";
import SomeCategoryList from '../../components/some-category-list';
import SomeGoodList from '../../components/some-good-list';

const HomeIndex = () => {
    return (
      <Container>
        <HomeIndexBanner />
        <SomeGoodList title={"Хиты продаж"} article={"123"} type={"hits"} />
        <SomeCategoryList
          type={"popular"}
          title={"Популярные категории товаров"}
        />
        <SomeGoodList title={"Новинки"} article={"123"} type={""} />
      </Container>
    );
};

export default observer(HomeIndex);