import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../../components/navbar";
import "../../css/public.css";
import { observer } from "mobx-react-lite";
import Footer from "../../components/footer";
import ContactBanner from "../../components/contact-banner";
import { Container } from "react-bootstrap";
import MobileMenu from "../../components/mobile-menu";
import Header from "../../components/header";
import Snowfall from "react-snowfall";
// import NewYear from "../../components/new-year";

const Layout = () => {
  return (
    <>
      <Header />
      {/* <NewYear /> */}
      <Navbar />
      <main>
        <Outlet />
        <Container>
          <ContactBanner />
        </Container>
      </main>
      <MobileMenu />
      <Footer />
    </>
  );
};

export default observer(Layout);
