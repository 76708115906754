import { ThemeProvider } from "react-bootstrap"
import { ImageModel } from "./image-model"

export class GoodModel {
    constructor(data) {
        if (data) {
            this.id = data.id
            this.name = data.name
            this.article = data.article
            this.images = ImageModel.getAll(data.images)
            this.description = data.description
            this.salePrice = data.salePrice
            this.singlePrice = data.singlePrice
            this.inBox = data.inBox
            this.isBox = data.isBox
            this.minOpt = data.minOpt
            this.inPack = data.inPack
            this.isActive = data.isActive
        }
        else {
            this.id = 0
            this.name = 'Some good'
            this.article = 'A123'
            this.images = [new ImageModel()]
            this.description = 'Some description'
            this.salePrice = 1000
            this.singlePrice = 10
            this.inBox = 100
            this.minOpt = 0
            this.isBox = 1
            this.inPack = 1
        }
    }

    static getAll(data) {
        let list = []
        for (let item of data) {
            list.push(new GoodModel(item))
        }
        return list
    }
}