import React, { useState } from 'react';
import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { fetchCategories, fetchDownloadPriceList } from '../../http/shop-api';
import axios from 'axios'

const PriceList = () => {

    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [categoryId, setCategoryId] = useState(0);
    const [categories, setCategories] = useState(null)

    useEffect(() => {
        fetchCategories().then(data =>
            setCategories(data)
        )
    }, [])

    const handleForm = (e) => {
        e.preventDefault()

        axios({
            url: process.env.REACT_APP_API_URL+'/Catalog/DownloadPriceList', //your url
            method: 'POST',
            responseType: 'blob', // important
            data: {
                name,
                phone,
                email,
                categoryId
            }
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);
        
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'Прай-лист.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
        
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    }

    return (
        <Container className='pt-4'>
            <h2>Загрузка прайс-листа</h2>
            <form onSubmit={(e) => handleForm(e)} id="price-list-form">
                <p>Для того, чтобы скачать прайс-лист, выберите необходимую вам категорию товаров. Скачивание начнется как только вы заполните форму.</p>

                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="name@example.com" required />
                    <label htmlFor="name"><span>*</span> Имя</label>
                </div>

                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="name@example.com" required />
                    <label htmlFor="phone"><span>*</span> Телефон</label>
                </div>

                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="name@example.com" />
                    <label htmlFor="email">Почта</label>
                </div>

                <div className="form-floating mb-3">
                    <select className="form-select" id="categoryId" defaultValue={categoryId} onChange={(e) => setCategoryId(e.target.value)} aria-label="Floating label select example" required>

                        {
                            categories ?
                                <>
                                    {
                                        categories.map(m =>
                                            <option key={m.id} value={m.id}>{m.name}</option>
                                        )
                                    }
                                </> : <>
                                    <option defaultValue={0}>Загрузка списка</option>
                                </>
                        }
                    </select>
                    <label htmlFor="categoryId">Категория товаров</label>
                </div>

                <button className='btn btn-primary' type='submit'>Скачать</button>

            </form>
        </Container>
    );
}

export default PriceList;
