import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Container} from "react-bootstrap";
import {Context, numberWithSpaces} from "../../index";
import BasketGood from "../../components/basket-good";
import MakeOrder from "../../components/make-order";

const Basket = () => {
    const {shopStore} = useContext(Context)
    return (
        <Container className={'pt-4'}>
            <h3 className={'fw-bold'}>Корзина</h3>
            {
                shopStore.basket.length > 0 ?
                    <>
                        <div className={'basket-good-list shadow-sm'}>
                            {
                                shopStore.basket.map(m =>
                                    <BasketGood key={m.good.id} item={m}/>
                                )
                            }
                            <div className="total">
                                <h5>
                                    В корзине {shopStore.totalCount()} кор. товаров
                                </h5>
                                <h4>Итого: <span>{numberWithSpaces(shopStore.totalSum())} тг</span></h4>
                            </div>

                        </div>
                        <MakeOrder/>
                    </>
                    : <div className={'basket-good-list shadow-sm'}>

                        <div style={{height: 400}}>
                            <h5 className={'text-muted'}>Корзина пустая</h5>
                            <a className={'btn btn-primary'} href={'/'}>Перейти в магазин</a>
                        </div>
                    </div>
            }
        </Container>
    );
};

export default observer(Basket);