import React, { useContext } from "react";
import { Context, numberWithSpaces } from "../index";
import { observer } from "mobx-react-lite";
import { formatPrice } from "../helpers/price";

const BasketGood = ({ item }) => {
  const { shopStore } = useContext(Context);
  return (
    <div className={"basket-good-card"}>
      <div className={"d-flex"} style={{ gap: 15 }}>
        <div
          className="img"
          style={{
            backgroundImage: `url('${
              item?.good?.images?.filter((img) => img?.path.includes("_min"))[0]
                ?.path
            }')`,
          }}
        ></div>
        <div className="info">
          <small>
            Артикул: <span>{item.good.article}</span>
          </small>
          <p>{item.good.name}</p>
        </div>
      </div>
      <div className={"d-flex"} style={{ gap: 30 }}>
        <div className="sale-price">
          <p>Стоимость за коробку</p>
          <h5>
            {formatPrice(item.good)}{" "}
            тг
          </h5>
        </div>
        <div className="counter">
          <div
            className="btn-group d-block"
            role="group"
            aria-label="Basic example"
          >
            <button
              type="button"
              onClick={() => shopStore.setGoodAmountBasket(item.good.id, -1)}
              className="btn btn-primary"
            >
              -
            </button>
            <button type="button" className="btn btn-light disabled border">
              {item.amount}
            </button>
            <button
              type="button"
              onClick={() => shopStore.setGoodAmountBasket(item.good.id, 1)}
              className="btn btn-primary"
            >
              +
            </button>
          </div>
          <button
            type={"button"}
            className={"btn btn-link"}
            onClick={() => shopStore.unsetBasket(item.good.id)}
          >
            Удалить
          </button>
        </div>
        <div className="sum-price">
          <p>Сумма</p>
          <h5>
            {formatPrice(item.good)}{" "}
            тг
          </h5>
        </div>
      </div>
    </div>
  );
};

export default observer(BasketGood);
