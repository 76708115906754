import React, {useContext, useState} from 'react';
import {observer} from "mobx-react-lite";
import {fetchLogin} from "../http/admin-api";
import {Context} from "../index";

const LoginForm = () => {

    const [userName,setUserName] = useState('')
    const [password,setPassword] = useState('')

    const {adminStore} = useContext(Context)
    const loginHandler = (e)=>{
        e.preventDefault()
        fetchLogin(userName,password).then((data)=>{
            if(data.errorText) {
                alert('Некорректные данные для входа')
            }
            else {
                adminStore.setAuth(data)
            }
        })
    }

    return (
        <div>
            <form onSubmit={(e)=>loginHandler(e)}>
                <input type={"text"} name={'username'} value={userName} onChange={(e)=>setUserName(e.target.value)}/>
                <input type={'password'} name={'password'} value={password} onChange={(e)=>setPassword(e.target.value)}/>
                <button type={'submit'}>Войти</button>
            </form>

        </div>
    );
};

export default observer(LoginForm);