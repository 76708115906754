import React, { useEffect, useState } from 'react';
import { fetchSomeGoodsInSection, fetchRelatedGoods, fetchNewGoods, fetchHits } from "../http/shop-api";
import { GoodModel } from '../models/good-model';
import GoodListCard from "./good-list-card";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

const SomeGoodList = ({ title, article, type }) => {
    const [goods, setGoods] = useState(null)

    useEffect(() => {
        if (type == "related") {
            fetchRelatedGoods(article).then(data =>
                setGoods(data)
            )
        }
        else if (type == "new") {
            fetchNewGoods().then(data =>
                setGoods(GoodModel.getAll(data)))
        }
        else if (type == "hits") {
            fetchHits().then(data =>
                setGoods(GoodModel.getAll(data)))
        }
        else {
            fetchSomeGoodsInSection(article).then(data =>
                setGoods(data)
            )
        }

    }, [])

    return (
        goods?.length>0 ? 
        <div className={'some-goods'}>
            <h4>
                {title}
            </h4>
            <div className="d1">
                <Swiper
                    modules={[Autoplay]}
                    spaceBetween={20}
                    slidesPerView={5}
                    autoplay={true}
                    loop={goods?.length>5}
                >
                    {
                        goods ?
                            <>
                                {
                                    goods.length>0 && goods.map(m =>
                                        <SwiperSlide key={m.id}>
                                            <GoodListCard good={m} />
                                        </SwiperSlide>
                                    )
                                }
                            </> : <>
                                <SwiperSlide>
                                    <div className={'good-list-card download'}>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'good-list-card download'}>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'good-list-card download'}>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'good-list-card download'}>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'good-list-card download'}>
                                    </div>
                                </SwiperSlide>
                            </>
                    }



                </Swiper>
            </div>
            <div className="d2">
                <Swiper
                    modules={[Autoplay]}
                    spaceBetween={20}
                    slidesPerView={4}
                    autoplay={true}
                    loop={true}
                >
                    {
                        goods ?
                            <>
                                {
                                    goods.length>0 && goods.map(m =>

                                        <SwiperSlide key={m.id}>
                                            <GoodListCard good={m} />
                                        </SwiperSlide>
                                    )
                                }
                            </> : <>
                                <SwiperSlide>
                                    <div className={'good-list-card download'}>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'good-list-card download'}>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'good-list-card download'}>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'good-list-card download'}>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'good-list-card download'}>
                                    </div>
                                </SwiperSlide>
                            </>
                    }



                </Swiper>
            </div>
            <div className="d3">
                <Swiper
                    modules={[Autoplay]}
                    spaceBetween={20}
                    slidesPerView={2}
                    autoplay={true}
                    loop={true}
                >
                    {
                        goods ?
                            <>
                                {
                                    goods.length>0 && goods.map(m =>

                                        <SwiperSlide key={m.id}>
                                            <GoodListCard good={m} />
                                        </SwiperSlide>
                                    )
                                }
                            </> : <>
                                <SwiperSlide>
                                    <div className={'good-list-card download'}>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'good-list-card download'}>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'good-list-card download'}>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'good-list-card download'}>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className={'good-list-card download'}>
                                    </div>
                                </SwiperSlide>
                            </>
                    }



                </Swiper>
            </div>

        </div>
        :<></>
    );
};

export default SomeGoodList;