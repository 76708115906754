import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import Finder from "./finder";
import { Container } from "react-bootstrap";
import { Context } from "../index";
import NavbarMenuItem from "./navbar-menu-item";
import { ReactComponent as Logo } from "../assets/icons/ddw.svg";
const Navbar = () => {
  const { shopStore } = useContext(Context);

  return (
    <nav className={"shadow-sm sticky-top"}>
      <Container>
        <div className="u-nav-1">
          <div className="logo">
            <a href="/">
              <div className={"d-flex align-items-center"}>
                <img
                  src={process.env.PUBLIC_URL + "/logo.png"}
                  width={26}
                  alt=""
                />
                <div>
                  <h4>
                    UPACK.KZ{" "}
                    <span className="mobile-ddw-logo">
                      <Logo />
                    </span>
                  </h4>
                  <h6>ОДНОРАЗОВАЯ ПОСУДА И УПАКОВКА</h6>
                </div>
              </div>
            </a>
          </div>
          <Finder />
          <ul className="contacts">
            {shopStore.phones.map((m, index) => {
              return (
                <li key={index}>
                  <a href={"tel:+" + m.replace(/\D/g, "")}>{m}</a>
                </li>
              );
            })}
          </ul>
          <ul className="buttons">
            <li className="cart">
              <a href="/basket">
                {shopStore.basket.length == 0 ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-cart"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-cart-fill me-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                    </svg>
                    {shopStore.totalCount()}
                  </>
                )}
                <p>Корзина</p>
              </a>
            </li>
            {/*<li className="login">*/}
            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"*/}
            {/*         className="bi bi-person" viewBox="0 0 16 16">*/}
            {/*        <path*/}
            {/*            d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>*/}
            {/*    </svg>*/}
            {/*</li>*/}
          </ul>
        </div>
        <div className="u-nav-2">
          <ul className={"menu"}>
            {shopStore.menu.length > 0 ? (
              <>
                {shopStore.menu.map((m, index) => {
                  return (
                    <li key={index}>
                      <NavbarMenuItem generalSection={m} />
                    </li>
                  );
                })}
                <li className="ddw-logo">
                  <Logo />
                </li>
              </>
            ) : (
              <>
                <li>
                  <div className="download menu-item"></div>
                </li>
                <li>
                  <div className="download menu-item"></div>
                </li>
                <li>
                  <div className="download menu-item"></div>
                </li>
                <li>
                  <div className="download menu-item"></div>
                </li>
                <li>
                  <div className="download menu-item"></div>
                </li>
                <li>
                  <div className="download menu-item"></div>
                </li>
              </>
            )}
          </ul>
        </div>
      </Container>
    </nav>
  );
};

export default observer(Navbar);
