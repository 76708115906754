import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import { Container } from 'react-bootstrap'
import { Context, numberWithSpaces } from '../..'
import { fetchB24AddLead, fetchCities, fetchTelegramBot } from '../../http/shop-api'

const Thanks = () => {

    const { shopStore } = useContext(Context)
    const [order,setOrder] = useState(null)
    const [basket,setBasket] = useState(null)
    const [totalSum,setTotalSum] = useState(0)

    

    useEffect(() => {
        fetchCities().then((data)=>{
            fetchTelegramBot(shopStore.currentOrder.id)
            fetchB24AddLead(shopStore.currentOrder,shopStore.basket,data).then((response)=>{console.log(response)})
            
        })
        setOrder(shopStore.currentOrder)
        setBasket(shopStore.basket)
        setTotalSum(shopStore.totalSum())

        setTimeout(() => {
            shopStore.doneCurrentOrder()
            shopStore.removeBasket()
        }, 2000);

    }, [])

    return order ? (
      <Container className="pt-4">
        <h3 className={"fw-bold text-success"}>Спасибо за покупку!</h3>
        <h5>
          Заказ <span>№ {order.id}</span> сформирован!
        </h5>

        <p>Мы свяжемся с вами в ближайшее время!</p>

        <ul className="list-group thanks-list mb-3">
          {basket.map((m) => (
            <li className="list-group-item good-info" key={m.good.id}>
              <div className="d-flex align-items-center" style={{ gap: 30 }}>
                <div
                  className="img"
                  style={{
                    backgroundImage: `url('${
                      m.good.images.filter((m) => m.path.includes("_min"))[0]
                        .path
                    }')`,
                  }}
                ></div>
                <div className="info">
                  <small>Артикул: {m.good.article}</small>
                  <h5>{m.good.name}</h5>
                </div>
              </div>
              <div className="d-flex align-items-center" style={{ gap: 30 }}>
                <div className="sale-price">
                  <p>
                    {numberWithSpaces(
                      m?.good.isBox === 1
                        ? m?.good.inBox * m?.good.singlePrice
                        : m?.good.inPack * m?.good.singlePrice
                    )}{" "}
                    тг х {m.good?.minOpt}
                  </p>
                </div>
                <div className="total-price">
                  <h5>
                    {" "}
                    {numberWithSpaces(
                      m?.good.isBox === 1
                        ? (
                            m?.good.inBox *
                            m?.good.minOpt *
                            m?.good.singlePrice
                          )?.toFixed(0)
                        : (
                            m?.good.inPack *
                            m?.good.minOpt *
                            m?.good.singlePrice
                          )?.toFixed(0)
                    )}{" "}
                    тг
                  </h5>
                </div>
              </div>
            </li>
          ))}
          {order.saleTypeId > 1 ? (
            <>
              <li className="list-group-item justify-content-end">
                <h5>Доставка:</h5>
                <h5>
                  {" "}
                  {order.saleTypeId == 2
                    ? totalSum > 35000
                      ? "Беслпатно"
                      : "2 800 тг"
                    : "Зависит от расположения получателя"}
                </h5>
              </li>
            </>
          ) : (
            <></>
          )}
          <li className="list-group-item justify-content-end">
            <h5>Итого: </h5>
            <h5>
              {numberWithSpaces(
                totalSum +
                  (order.saleTypeId == 2 && totalSum < 35000 ? 2800 : 0)
              )}{" "}
              тг
            </h5>
          </li>
        </ul>

        <p className="mb-0">
          Тип доставки:{" "}
          <b>
            {order.saleTypeId == 1
              ? "Самовывоз"
              : order.saleTypeId == 2
              ? "Доставка по городу"
              : "Доставка по стране"}
          </b>
        </p>
        <p>
          Оплата:{" "}
          <b>
            {order.payTypeId == 1
              ? "При получении товара"
              : "Онлайн-оплата (Заказ оплачен)"}
          </b>
        </p>

        <button
          className="btn btn-primary"
          onClick={() => {
            shopStore.doneCurrentOrder();
            shopStore.removeBasket();
            window.location.href = "/";
          }}
        >
          Вернуться в магазин
        </button>
      </Container>
    ) : (
      <></>
    );
}

export default observer(Thanks)