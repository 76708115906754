import React from 'react';

const CategoryListCard = ({category}) => {
    return (
        <a href={"/catalog/category/"+category.id}>
            <div className="category-list-card">
                <div className="img" style={{backgroundImage:`url('${category.image}')`}}></div>
                <p>{category.name}</p>
            </div>
        </a>
    );
};

export default CategoryListCard;