import React from 'react';

const Privacy = () => {
    return (
        <div class="container mt-4">
    <div class="alert alert-warning">
        <h4>
            Администрация Интернет-магазина   upack.kz    гарантирует обеспечение полной конфиденциальности информации, полученной от зарегистрированных пользователей
        </h4>
    </div>

    <p>
        Вся информация, которую Вы укажете при регистрации, будет храниться в защищенной базе данных. Интернет-магазин upack.kz  гарантирует полную конфиденциальность при исполнении заказа, а также то, что информация о покупателе будет использована только для исполнения поступившего заказа. Ваши регистрационные данные нам нужны исключительно для того, чтобы наши менеджеры смогли с Вами связаться, а служба доставки смогла доставить заказанный Вами товар в нужное место в срок. Кроме того, зарегистрировавшись, Вы сможете получать информацию о всех новостях нашего магазина: новых поступлениях товара, специальных программах и акциях.
    </p>
    <p>
        Если у Вас возникнут какие-либо проблемы или вопросы по авторизации-регистрации в магазине - пишите на адрес электронной почты и мы всегда поможем Вам!
    </p>
    <p>
        Представители  интернет-магазина upack.kz  никогда не попросят у Вас данные пластиковой карты или другие конфиденциальные реквизиты.
    </p>

    <h5>Личные сведения и безопасность:</h5>
    <ol>
        <li>
            Интернет-магазин upack.kz   гарантирует, что никакая полученная от Вас информация никогда и ни при каких условиях не будет предоставлена третьим лицам, за исключением случаев, предусмотренных действующим законодательством Республики Казахстан.
        </li>
        <li>
            В определенных обстоятельствах интернет-магазин upack.kz   может попросить Вас зарегистрироваться и предоставить личные сведения. Предоставленная информация используется исключительно при обработке заказа в Интернет-магазине или для предоставления посетителю доступа к специальной информации.
        </li>
        <li>
            Личные сведения можно изменить, обновить или удалить в любое время в разделе "Личный кабинет".
        </li>
        <li>
            Для того чтобы обеспечить Вас информацией определенного рода, интернет-магазин upack.kz  может присылать на указанный при регистрации адрес электронный почты информационные сообщения. Но Вы можете в любой момент от них отказаться.
        </li>
        <li>
            Сведения на данном сайте имеют чисто информативный характер, в них могут быть внесены любые изменения без какого-либо предварительного уведомления.
        </li>
    </ol>

</div>
    );
}

export default Privacy;
