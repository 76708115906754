import React, { useContext, useEffect, useState } from 'react';
import { Toast } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Context, numberWithSpaces } from '../..';
import { fetchFinder } from '../../http/shop-api';

const Finder = () => {

  const { query } = useParams()

  const [goods, setGoods] = useState([])
  const [categories, setCategories] = useState([])
  const { shopStore } = useContext(Context)
  const [showA, setShowA] = useState(false);

  useEffect(() => {
    fetchFinder(query).then((data) => {
      setGoods(data.goods?? [])
      setCategories(data.categories ?? [])
    })
  }, [])

  const toggleShowA = () => {
    setShowA(!showA)
    setTimeout(() => {
      setShowA(false)
    }, 5000);
  };

  return (


    <div className='container'>
      <Toast id="added-to-basket" show={showA} onClose={toggleShowA}>
        <Toast.Header>
          <img
            src="holder.js/20x20?text=%20"
            className="rounded me-2"
            alt=""
          />
          <strong className="me-auto">Корзина</strong>
        </Toast.Header>
        <Toast.Body>Товар добавлен в корзину!</Toast.Body>
      </Toast>

      <div className="mt-5">
        {
          categories.length > 0 ?
            <>
              <h3 className='mb-3'>Найденные категории</h3>
              <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
                {
                  categories.map((category, index) => {
                    return <a className='category-button' href={"/catalog/category/" + category.id}>{category.name}</a>
                  })
                }
              </div>
            </> :
            <></>
        }

      </div>

      <div className="in-category" style={{ minHeight: 700 }}>
        <div className="good-list">
          {
            goods.length > 0 ?
              <>
                <h3 className='mb-3'>Найденные товары</h3>
                <div className="list">
                  {
                    goods.map((m, index) => {
                      return (
                        <div key={index} className={"item in-finder shadow-sm"}>
                          <a href={"/catalog/good/" + m?.article}>
                            <div
                              className="img lazy"
                              style={{
                                backgroundImage: `url('${
                                  m?.images.filter((i) =>
                                    i?.path.includes("_min")
                                  )[0]?.path
                                }')`,
                              }}
                            ></div>
                            <div className="content">
                              <small>Артикул: {m.article}</small>
                              <h5 title={m.name}>{m.name}</h5>
                              <div className="cost">
                                <h6>
                                  <span>
                                    {numberWithSpaces(
                                      m.isBox === 1
                                        ? (
                                            m?.inBox *
                                            m?.minOpt *
                                            m?.singlePrice
                                          )?.toFixed(0)
                                        : (
                                            m?.inPack *
                                            m?.minOpt *
                                            m?.singlePrice
                                          )?.toFixed(0)
                                    )}{" "}
                                    тг/кор
                                  </span>
                                </h6>
                                <p>
                                  <span>
                                    {numberWithSpaces(m.singlePrice.toFixed(2))}
                                  </span>{" "}
                                  тг/{m.dimension}
                                </p>
                              </div>
                            </div>
                          </a>

                          {shopStore.inBasket(m.id) ? (
                            <button className={"btn btn-success"}>
                              Перейти к корзине
                            </button>
                          ) : (
                            <button
                              className={"btn btn-primary"}
                              onClick={() => {
                                shopStore.setBasket(m);
                                toggleShowA();
                              }}
                            >
                              Добавить в корзину
                            </button>
                          )}
                        </div>
                      );
                    })
                  }
                </div>
              </>
              : <></>
          }

        </div>

      </div>




    </div>
  );
};

export default Finder;