import React, { useContext, useState } from "react";
import { observer } from "mobx-react-lite";
import { Context } from "../index";
import { Col, Row } from "react-bootstrap";

const NavbarMenuItem = ({ generalSection }) => {
  const [active, setActive] = useState(false);
  const compareSections = (a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  };

  const sortedSections = [...generalSection.sections].sort(compareSections);
  return (
    <div
      className={"menu-item"}
      onMouseOver={(e) => setActive(true)}
      onMouseLeave={(e) => setActive(false)}
    >
      <span>{generalSection.name}</span>
      {active ? (
        <div className="categories">
          <div className="white-bg">
            <Row>
              {sortedSections.map((section) => (
                <div
                  key={section.id}
                  className={
                    "col-md-" +
                    (generalSection.sections.length == 2
                      ? "6"
                      : "3 text-center")
                  }
                >
                  {generalSection.sections.length == 2 ? (
                    <>
                      <img src={section.image} alt="" />
                    </>
                  ) : (
                    <></>
                  )}

                  <h4>{section.name}</h4>

                  <ul>
                    {section.categories.map((m) => (
                      <li key={m.id}>
                        <a href={"/catalog/category/" + m.id}>{m.name}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </Row>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default observer(NavbarMenuItem);
