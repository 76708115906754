import React from "react";
import { observer } from "mobx-react-lite";
import { Context, numberWithSpaces } from "../index";
import { formatPrice } from "../helpers/price";
const FinderGoodItem = ({ good }) => {
  let image = good.images.filter((img) => img?.path.includes("_min"))[0]?.path;
  return (
    <a href={"/catalog/good/" + good.article}>
      <div className="good-item">
        <img src={image} width={80} alt="" />
        <div className="good-info">
          <h5>{good.name}</h5>
          <small>Артикул: {good.article}</small>
          <h6>
            {" "}
            {formatPrice(good)}{" "}
            тг/кор
          </h6>
        </div>
      </div>
    </a>
  );
};

export default observer(FinderGoodItem);
