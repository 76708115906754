import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Context } from "..";

import Offcanvas from "react-bootstrap/Offcanvas";
import { fetchFinder } from "../http/shop-api";
import FinderGoodItem from "./finder-good-item";
import Contacts from "../pages/home/contacts";

const MobileMenu = () => {
  const { shopStore } = useContext(Context);
  useEffect(() => {}, []);

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showMobileFinder, setShowMobileFinder] = useState(false);
  const [showMobileContacts, setShowMobileContacts] = useState(false);

  const [value, setValue] = useState("");

  const handleCatalog = () => {
    setShowMobileMenu(!showMobileMenu);
    setShowMobileContacts(false);
    setShowMobileFinder(false);
  };

  const handleFinder = () => {
    setShowMobileFinder(!showMobileFinder);
    setValue("");
    setShowMobileMenu(false);
    setShowMobileContacts(false);

    setTimeout(() => {
      if (document.getElementById("mobile-finder-input")) {
        document.getElementById("mobile-finder-input").focus();
      }
    }, 300);
  };

  const handleContacts = () => {
    setShowMobileContacts(!showMobileContacts);
    setShowMobileFinder(false);
    setShowMobileMenu(false);
  };

  const handleFinderPost = (query) => {
    setValue(query);
    if (query.length > 2) {
      fetchFinder(query).then((data) => {
        shopStore.setFinderGoods(data.goods);
        shopStore.setFinderCategories(data.categories);
      });
    } else {
      shopStore.setFinderGoods(null);
      shopStore.setFinderCategories(null);
    }
  };

  return (
    <>
      <Offcanvas
        id="mobile-menu-catalog"
        show={showMobileMenu}
        onHide={() => setShowMobileMenu(false)}
        placement={"bottom"}
        name={"bottom"}
      >
        <Offcanvas.Header closeButton className="shadow-sm">
          <Offcanvas.Title>Каталог</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {shopStore.menu.map((gs) => {
            return (
              <ul key={gs.id} className="mobile-menu-general-section-list">
                <li>
                  <h5>{gs.name}</h5>
                  <ul className="mobile-menu-section-list">
                    {gs.sections.map((s) => {
                      return (
                        <li key={s.id}>
                          <h6>{s.name}</h6>
                          <ul className="mobile-menu-category-list list-group">
                            {s.categories.map((c) => {
                              return (
                                <li key={c.id} className="list-group-item">
                                  <a href={"/catalog/category/" + c.id}>
                                    <div>{c.name}</div>
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                          <img src={s.image} alt="" />
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            );
          })}
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        id="mobile-menu-finder"
        show={showMobileFinder}
        onHide={() => setShowMobileFinder(false)}
        placement={"bottom"}
        name={"bottom"}
      >
        <Offcanvas.Header closeButton className="shadow-sm">
          <Offcanvas.Title className="flex-grow-1">
            <input
              type="text"
              id="mobile-finder-input"
              value={value}
              onChange={(e) => handleFinderPost(e.target.value)}
              className="form-control w-100"
              placeholder="поиск по сайту"
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {shopStore.finderGoods != null ||
          shopStore.finderCategories != null ? (
            <div className={"result"}>
              {shopStore.finderCategories != null ? (
                <>
                  <h5 className="mb-3">Категории</h5>
                  <ul
                    className={"mb-3"}
                    style={
                      shopStore.finderGoods != null
                        ? {
                            borderBottom: "1px solid #2B377AFF",
                            paddingBottom: 20,
                          }
                        : {}
                    }
                  >
                    {shopStore.finderCategories.map((m) => (
                      <li key={m.id}>
                        <a
                          href={"/catalog/category/" + m.id}
                          className={"category-button"}
                        >
                          {m.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <></>
              )}
              {shopStore.finderGoods != null ? (
                <>
                  <h5 className="mb-3">Товары</h5>
                  <ul>
                    {shopStore.finderGoods.map((m) => (
                      <li key={m.id}>
                        <FinderGoodItem good={m} />
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        id="mobile-menu-contacts"
        show={showMobileContacts}
        onHide={() => setShowMobileContacts(false)}
        placement={"bottom"}
        name={"bottom"}
      >
        <Offcanvas.Header closeButton className="shadow-sm">
          <Offcanvas.Title>Контакты</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Contacts />
        </Offcanvas.Body>
      </Offcanvas>

      <div className="mobile-menu">
        <a onClick={() => handleCatalog()}>
          <div className="item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="bi bi-list"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
              />
            </svg>
          </div>
        </a>

        <a onClick={() => handleFinder()}>
          <div className="item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
            </svg>
          </div>
        </a>
        <a onClick={() => handleContacts()}>
          <div className="item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="bi bi-telephone-fill"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
              />
            </svg>
          </div>
        </a>
        <a href="/basket">
          <div className="item">
            {shopStore.basket.length == 0 ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="bi bi-cart"
                viewBox="0 0 16 16"
              >
                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="bi bi-cart-fill"
                viewBox="0 0 16 16"
              >
                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </svg>
            )}
          </div>
        </a>
      </div>
    </>
  );
};

export default observer(MobileMenu);
