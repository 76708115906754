import { $host, $authHost } from "./index-api";

export const fetchFinder = async (query) => {
    const goods = await $host.get('/Catalog/GetGoodsByQuery', {
        params: {
            query: query
        }
    })
    const categories = await $host.get('/Catalog/GetCategories', {
        params: {
            query: query
        }
    })

    return {
        goods: (goods.data.length > 0 ? goods.data : null),
        categories: (categories.data.length > 0 ? categories.data : null)
    }
}


export const fetchHomeIndexBanner = async () => {
    const { data } = await $host.get('/Catalog/GetHomeIndexBanner')
    return data
}

export const fetchMenu = async () => {
    const { data } = await $host.get('/catalog/getmenusections')
    return data
}
fetchMenu()
export const fetchCategory = async (id) => {
    const category_response = await $host.get('/catalog/getcategories')
    let category = category_response.data
    category = category.filter(m => m.id == id)[0]
    return category
}

export const fetchCategories = async (id) => {
    const { data } = await $host.get('/catalog/getcategories', {
        params: {
            sectionId: id
        }
    })
    return data
}

export const fetchPopularCategories = async () => {
    const { data } = await $host.get('/catalog/GetPopularCategories')

    return data
}

export const fetchGoods = async (params) => {
    const { data } = await $host.get('/catalog/getgoods', {
        params
    })
    return data
}

export const fetchGood = async (article) => {
    const { data } = await $host.get('/catalog/getgood', {
        params: {
            article
        }
    })
    return data
}

export const fetchRelatedGoods = async (article) => {
    const { data } = await $host.get('/catalog/GetRelatedGoods', {
        params: {
            article
        }
    })
    return data
}

export const fetchSomeGoodsInSection = async (article) => {
    const { data } = await $host.get('/catalog/GetSomeGoodsInSection', {
        params: {
            article
        }
    })

    return data
}

export const fetchNewGoods = async ()=>{
    const {data} = await $host.get('/Catalog/GetNewGoods')
    return data
}

export const fetchCities = async () => {
    const { data } = await $host.get('/catalog/getcities')

    return data
}

export const fetchDeliveryPrice = async () => {
    const { data } = await $host.get('/basket/GetDeliveryPrice')
    return data
}

export const fetchMakeOrder = async (fd) => {
    const { data } = await $host.post('/basket/MakeOrder', fd)
    return data
}

export const fetchTelegramBot = async (id)=>{
    const response = await $host.post('/basket/SendToTelegramBot?orderId='+id)
    return response
}

export const fetchB24AddLead = async (order, basket, cities) => {

    let basketString = 'Товары: <br/>'
    for (var i = 0; i < basket.length; i++) {
        basketString += `${i + 1}) ${basket[i].good.article} x ${basket[i].amount} <br/>`
    }
    basketString += `Оплата: ${order.payTypeId == 1 ? 'При получении товара' : 'Онлайн-оплата'}<br/>`
    basketString += `Доставка: ${order.saleTypeId == 1 ? 'Самовывоз' : order.saleTypeId == 2 ? 'Доставка по Алматы' : 'Доставка по Казахстану'}`


    const response = await $host.post('/basket/MakeOrder_B24', {
            id:order.id,
            firstName:order.firstName,
            secondName:order.secondName,
            cityId:order.cityId,
            phone:order.phone,
            email:order.email,
            totalSum : order.totalSum,
            basketString
    })
    return response
}

export const fetchDownloadPriceList = async (fd) => {
    const { data } = await $host.post('/Catalog/DownloadPriceList', fd)
    return data
}

export const fetchHits = async () => {
    const { data } = await $authHost.get('/Catalog/GetHits')
    return data
}

export const fetchSetHit = async (id,status) => {
    const {data} = await $authHost.post('/Admin/SetHit', {
        id, status
    })
    return data
}


export const fetchSetPopularCategory = async (fd)=> {
    const {data} = await $authHost.post('/Admin/SetPopularCategory',fd)
    return data
}
