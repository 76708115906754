import {makeAutoObservable} from "mobx";
import { getMinutesDifference } from "..";

export default class ShopStore {
  constructor() {
    this._phones = ["+7 (747) 094-22-07"];
    // this._phones = [
    //     "+7 (700) 428 25 13",
    //     "+7 (708) 701 22 62"
    // ]
    this._finderGoods = null;
    this._finderCategories = null;
    this._basket = JSON.parse(localStorage.getItem("basket")) || [];
    this._currentOrder =
      JSON.parse(localStorage.getItem("currentOrder")) || undefined;
    this._homeIndexBanner = [];
    this._menu = JSON.parse(localStorage.getItem("menu")) || [];
    this._goodListParams = JSON.parse(
      localStorage.getItem("goodListParams")
    ) || {
      page: 1,
      sortBy: "id",
      sortDirection: "asc",
      categoryId: 0,
      lastUpdate: new Date(),
    };
    if (getMinutesDifference(this._goodListParams) > 60) {
      this._goodListParams = {
        page: 1,
        sortBy: "id",
        sortDirection: "asc",
        categoryId: 0,
        lastUpdate: new Date(),
      };
      localStorage.setItem(
        "goodListParams",
        JSON.stringify(this._goodListParams)
      );
    }
    this._goodList = [];

    makeAutoObservable(this);
  }

  setPhones(data) {
    this._phones = data;
  }

  get phones() {
    return this._phones;
  }

  setFinderGoods(data) {
    this._finderGoods = data;
  }

  get finderGoods() {
    return this._finderGoods;
  }

  setFinderCategories(data) {
    this._finderCategories = data;
  }

  get finderCategories() {
    return this._finderCategories;
  }

  setBasket(good) {
    this._basket.push({
      good,
      amount: 1,
    });
    localStorage.setItem("basket", JSON.stringify(this._basket));
  }

  unsetBasket(id) {
    this._basket = this._basket.filter((m) => m.good.id != id);
    localStorage.setItem("basket", JSON.stringify(this._basket));
  }

  setGoodAmountBasket(id, count) {
    for (let item of this._basket) {
      if (item.good.id == id) {
        if (item.amount == 1 && count == -1) {
          return;
        }
        item.amount += count;
        break;
      }
    }
    localStorage.setItem("basket", JSON.stringify(this._basket));
  }

  inBasket(id) {
    for (let item of this._basket) {
      if (item.good.id == id) {
        return true;
      }
    }
    return false;
  }
  totalCount() {
    let counter = 0;
    for (let item of this._basket) {
      counter += item.amount;
    }
    return counter;
  }
  totalSum() {
    let sum = 0;
    for (let item of this._basket) {
      if (item?.good?.isBox === 1) {
        sum +=
          item.amount *
          (item?.good?.inBox * item?.good?.minOpt * item?.good?.singlePrice);
      } else {
        sum +=
          item.amount *
          (item?.good?.inPack * item?.good?.minOpt * item?.good?.singlePrice);
      }
    }
    return sum;
  }
  get basket() {
    return this._basket;
  }

  setHomeIndexBanner(data) {
    this._homeIndexBanner = data;
  }

  get homeIndexBanner() {
    return this._homeIndexBanner;
  }

  setMenu(data) {
    this._menu = data;
    localStorage.setItem("menu", JSON.stringify(this._menu));
  }

  get menu() {
    return this._menu;
  }
  getGoodListParams(id) {
    if (this._goodListParams.categoryId != id) {
      this._goodListParams = {
        page: 1,
        sortBy: "name",
        sortDirection: "asc",
        categoryId: id,
        lastUpdate: new Date(),
      };
      localStorage.setItem(
        "goodListParams",
        JSON.stringify(this._goodListParams)
      );
    }
    return this._goodListParams;
  }
  setGoodListParamsPage(page, categoryId) {
    this._goodListParams.page = page;
    this._goodListParams.categoryId = categoryId;
    this._goodListParams.lastUpdate = new Date();
    localStorage.setItem(
      "goodListParams",
      JSON.stringify(this._goodListParams)
    );
  }

  setGoodListParamsSortBy(sortBy, categoryId) {
    this._goodListParams.sortBy = sortBy;
    this._goodListParams.categoryId = categoryId;
    this._goodListParams.lastUpdate = new Date();
    localStorage.setItem(
      "goodListParams",
      JSON.stringify(this._goodListParams)
    );
  }
  setGoodListParamsSortDirection(sortDirection, categoryId) {
    this._goodListParams.sortDirection = sortDirection;
    this._goodListParams.categoryId = categoryId;
    this._goodListParams.lastUpdate = new Date();
    localStorage.setItem(
      "goodListParams",
      JSON.stringify(this._goodListParams)
    );
  }

  get goodListParams() {
    return this._goodListParams;
  }

  setGoodList(data) {
    this._goodList = data;
  }

  get goodList() {
    return this._goodList;
  }

  setCurrentOrder(order) {
    this._currentOrder = order;
    localStorage.setItem("currentOrder", JSON.stringify(this._currentOrder));
  }

  get currentOrder() {
    return this._currentOrder;
  }

  doneCurrentOrder() {
    this._currentOrder = undefined;
    localStorage.removeItem("currentOrder");
  }

  removeBasket() {
    this._basket = [];
    localStorage.removeItem("basket");
  }
}