import React, {useEffect, useState} from 'react';
import {fetchPopularCategories} from "../http/shop-api";
import CategoryListCard from "./category-list-card";

const SomeCategoryList = ({title,type}) => {

    const [list,setList] = useState(null)

    useEffect(()=>{
        if(type=="popular"){
            fetchPopularCategories().then(data=>setList(data))
        }
    },[])
    return (
        <div className={'some-categories'}>
            <h4>
                {title}
            </h4>
            <div className="category-list">
                {
                    list?<>
                        {
                            list.map(m=>
                                <CategoryListCard key={m.id} category={m}/>
                            )
                        }
                    </>:<>
                        <div className={'category-list-card download'}></div>
                        <div className={'category-list-card download'}></div>
                        <div className={'category-list-card download'}></div>
                        <div className={'category-list-card download'}></div>
                    </>
                }
            </div>
        </div>
    );
};

export default SomeCategoryList;