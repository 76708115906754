import React, { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Context, numberWithSpaces } from "../index";
import { fetchGoods } from "../http/shop-api";
import Pagination from "react-bootstrap/Pagination";
import { Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatPrice } from "../helpers/price";

const CatalogCategoryGoodList = ({ id }) => {
  const { shopStore } = useContext(Context);
  const [spinner, setSpinner] = useState(true);
  const [pagination, setPagination] = useState([]);
  const [sortValue, setSortValue] = useState(
    shopStore.getGoodListParams(id).sortBy +
      "," +
      shopStore.getGoodListParams(id).sortDirection
  );
  const navigate = useNavigate();
  const updateGoodList = () => {
    setSpinner(true);
    fetchGoods({
      ...shopStore.goodListParams,
      categoryId: id,
    }).then((data) => {
      shopStore.setGoodList(data.goods);
      if (data.count > 16) {
        let page_count = Math.ceil(data.count / 16);
        let array = [];
        for (let i = 0; i < page_count; i++) {
          array.push(
            <Pagination.Item
              key={i + 1}
              active={i + 1 == shopStore.getGoodListParams(id).page}
              onClick={() => {
                shopStore.setGoodListParamsPage(i + 1, id);
                updateGoodList();
                window.scrollTo(0, 0);
              }}
            >
              {i + 1}
            </Pagination.Item>
          );
        }
        setPagination(array);
      }
      setSpinner(false);

      document.addEventListener("DOMContentLoaded", function () {
        var lazyloadImages;

        if ("IntersectionObserver" in window) {
          lazyloadImages = document.querySelectorAll(".lazy");
          var imageObserver = new IntersectionObserver(function (
            entries,
            observer
          ) {
            entries.forEach(function (entry) {
              if (entry.isIntersecting) {
                var image = entry.target;
                image.classList.remove("lazy");
                imageObserver.unobserve(image);
              }
            });
          });

          lazyloadImages.forEach(function (image) {
            imageObserver.observe(image);
          });
        } else {
          var lazyloadThrottleTimeout;
          lazyloadImages = document.querySelectorAll(".lazy");

          function lazyload() {
            if (lazyloadThrottleTimeout) {
              clearTimeout(lazyloadThrottleTimeout);
            }

            lazyloadThrottleTimeout = setTimeout(function () {
              var scrollTop = window.pageYOffset;
              lazyloadImages.forEach(function (img) {
                if (img.offsetTop < window.innerHeight + scrollTop) {
                  img.src = img.dataset.src;
                  img.classList.remove("lazy");
                }
              });
              if (lazyloadImages.length == 0) {
                document.removeEventListener("scroll", lazyload);
                window.removeEventListener("resize", lazyload);
                window.removeEventListener("orientationChange", lazyload);
              }
            }, 20);
          }

          document.addEventListener("scroll", lazyload);
          window.addEventListener("resize", lazyload);
          window.addEventListener("orientationChange", lazyload);
        }
      });
    });
  };

  useEffect(() => {
    updateGoodList();
  }, []);

  const sortHandler = (e) => {
    setSortValue(e.target.value);
    let sort_value_split = e.target.value.split(",");
    shopStore.setGoodListParamsSortBy(sort_value_split[0], id);
    shopStore.setGoodListParamsSortDirection(sort_value_split[1], id);
    updateGoodList();
  };

  const [showA, setShowA] = useState(false);

  const toggleShowA = () => {
    setShowA(!showA);
    setTimeout(() => {
      setShowA(false);
    }, 5000);
  };

  return (
    <>
      <Toast id="added-to-basket" show={showA} onClose={toggleShowA}>
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Корзина</strong>
        </Toast.Header>
        <Toast.Body>Товар добавлен в корзину!</Toast.Body>
      </Toast>

      <div className={"good-list"}>
        <div className="category-sort">
          <p>Сортировать по: </p>
          <select
            className="category-select"
            value={sortValue}
            onChange={(e) => sortHandler(e)}
          >
            {/* <option value={"id,asc"}>По дате добавления (сначала старые)</option>
          <option value={"id,desc"}>По дате добавления (сначала новые)</option> */}
            <option value={"name,asc"}>По названию (А-Я)</option>
            <option value={"name,desc"}>По названию (Я-А)</option>
            <option value={"price,asc"}>По цене (сначала девешые)</option>
            <option value={"price,desc"}>По цене (сначала дорогие)</option>
          </select>
        </div>
        {spinner ? (
          <div className={"mt-3"}>
            <div className="list">
              <div className={"loading"}></div>
              <div className={"loading"}></div>
              <div className={"loading"}></div>
              <div className={"loading"}></div>
            </div>
          </div>
        ) : (
          <>
            <div className="list">
              {shopStore.goodList.length > 0 ? (
                <>
                  {shopStore.goodList.map((m) => (
                    <div key={m.id} className={"item shadow-sm"}>
                      <a href={"/catalog/good/" + m.article}>
                        <div
                          className="img lazy"
                          style={{
                            backgroundImage: `url('${
                              m.images.filter((img) =>
                                img?.path.includes("_min")
                              )[0]?.path
                            }')`,
                          }}
                        ></div>
                        <div className="content">
                          <small>Артикул: {m.article}</small>
                          <h5 title={m.name}>{m.name}</h5>
                          <div className="cost">
                            <h6>
                              <span>
                                {formatPrice(m)}{" "}
                                тг/коробка
                              </span>
                            </h6>
                            <p>
                              <span>
                                {numberWithSpaces(m.singlePrice.toFixed(2))}
                              </span>{" "}
                              тг/{m.dimension}
                            </p>
                          </div>
                        </div>
                      </a>
                      {shopStore.inBasket(m.id) ? (
                        <button
                          className={"btn btn-success"}
                          onClick={() => {
                            navigate("/basket");
                          }}
                        >
                          Перейти к корзине
                        </button>
                      ) : (
                        <button
                          className={"btn btn-primary"}
                          onClick={() => {
                            shopStore.setBasket(m);
                            toggleShowA();
                          }}
                        >
                          Добавить в корзину
                        </button>
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>

            <Pagination>{pagination}</Pagination>
          </>
        )}
      </div>
    </>
  );
};

export default observer(CatalogCategoryGoodList);
