import React from 'react';

const Payment = () => {
    return (
        <div class="container mt-4">
            <h2>Условия оплаты заказов</h2>
    <div class="alert alert-warning">
        <h4>
            Перед оплатой заказа необходимо получить подтверждение наличия товара у менеджера.
        </h4>
    </div>

    <h4>Способы оплаты</h4>
    <ul>
        <li>
            Онлайн оплата – Вы можете оплатить Ваши покупки карточкой Вашего банка прямо с нашего сайта. Обращаем Ваше внимание, что стоимость доставки рассчитывается и оплачивается отдельно.
        </li>
        <li>
            Оплата по счету – Вы присылаете реквизиты нашему специалисту по продажам, после чего он формирует счет на оплату и отправляет его Вам. В данном случае условия доставки оговариваются заранее и могут быть включены в счет.
        </li>
        <li>
            Оплата наличными курьеру – Вы можете оплатить товар наличными при получении. (Данный способ действителен только при сумме заказа свыше 35 000 тенге. Если сумма заказа ниже 35 000 тенге, способ оплаты согласовывается с нашим специалистом по продажам).
        </li>
    </ul>

</div>
    );
}

export default Payment;
