import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { fetchCategories, fetchPopularCategories } from '../../http/shop-api';

const ManagePopularCategory = () => {
    const [categories, setCategories] = useState([]);
    const [allCategories,setAllCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [name, setName] = useState('');
    const [image, setImage] = useState(null);

    useEffect(() => {
        loadCategories();
    }, []);

    const loadCategories = async () => {
        fetchPopularCategories().then((data)=>{
            setCategories(data)
        })
        fetchCategories().then((allCats)=>{
            setAllCategories(allCats)
        })
    };
    fetchCategories().then((allCats)=>{
        setAllCategories(allCats)
    })

    const handleSelectCategory = (category) => {
        setSelectedCategory(category);
        setName(category.name);
    };

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file.type === 'image/png') {
            const img = new Image();
            img.onload = () => {
                if (img.width === img.height) {
                    setImage(file);
                } else {
                    alert('Please select a square image.');
                }
            };
            img.src = URL.createObjectURL(file);
        } else {
            alert('Please select a PNG image.');
        }
    };

    const handleCreateCategory = async () => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('image', image);
        await axios.post('/api/categories', formData);
        await loadCategories();
    };

    const handleUpdateCategory = async () => {
        const formData = new FormData();
        formData.append('name', name);
        if (image) {
            formData.append('image', image);
        }
        await axios.put(`/api/categories/${selectedCategory.id}`, formData);
        await loadCategories();
        setSelectedCategory(null);
    };

    const handleDeleteCategory = async (category) => {
        if (window.confirm(`Are you sure you want to delete the category "${category.name}"?`)) {
            await axios.delete(`/api/categories/${category.id}`);
            await loadCategories();
            setSelectedCategory(null);
        }
    };

    return (
        <Container>
            <Row>
                <Col md={4}>
                    <h2>Добавить категорию в популярные</h2>
                    <Form>
                        <Form.Group className='mb-3'>
                            <Form.Label>Категория</Form.Label>
                            <Form.Select aria-label="Default select example" value={name} onChange={(e)=>setName(e.target.value)}>
                                {allCategories.map((category)=>{
                                    return <option key={category.name}>{category.name}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label>Картинка</Form.Label>
                            <Form.Control type="file" accept="image/png" onChange={handleFileSelect} />
                        </Form.Group>
                        <Button variant="primary"  onClick={handleCreateCategory}>Добавить в популярное</Button>
                    </Form>
                </Col>
                <Col md={8}>
                    <h2>Категории</h2>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Название</th>
                                <th>Картинка</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories.map((category) => (
                                <tr key={category.id}
                                    onClick={() => handleSelectCategory(category)}
                                    className={category === selectedCategory ? 'table-primary' : ''}
                                >
                                    <td>{category.name}</td>
                                    <td>{category.image ? <img src={category.image} alt={category.name} width="70" /> : '-'}</td>
                                    <td>
                                        <Button variant="danger" onClick={() => handleDeleteCategory(category)}>Удалить</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    {selectedCategory && (
                        <div>
                            <h3>Edit Category</h3>
                            <Form>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={name}
                                        onChange={(event) => setName(event.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Image</Form.Label>
                                    {selectedCategory.image && (
                                        <div>
                                            <img src={selectedCategory.image} alt={selectedCategory.name} width="50" />
                                            <br />
                                        </div>
                                    )}
                                    <Form.Control type="file" accept="image/png" onChange={handleFileSelect} />
                                </Form.Group>
                                <Button variant="primary" onClick={handleUpdateCategory}>Update</Button>
                                {' '}
                                <Button variant="secondary" onClick={() => setSelectedCategory(null)}>Cancel</Button>
                            </Form>
                        </div>
                    )}
                </Col>
            </Row>
        </Container>);
}

export default ManagePopularCategory;
