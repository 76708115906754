import { numberWithSpaces } from "..";

export function formatPrice(item) {
  let cost;

  if (item.isBox === 1) {
    cost = item.inBox * item.minOpt * item.singlePrice;
  } else {
    cost = item.inPack * item.minOpt * item.singlePrice;
  }

  // Округляем до целого числа и форматируем число с разделителями пробелами
  const formattedCost = cost.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  return formattedCost;
}

export function calculateTotal(item, good) {
  function calculateCost(obj) {
    if (obj.isBox === 1) {
      return obj.inBox * obj.minOpt * obj.singlePrice;
    } else {
      return obj.inPack * obj.minOpt * obj.singlePrice;
    }
  }

  const totalItemCost = calculateCost(item);
  const totalGoodCost = calculateCost(good);

  const totalCost = totalItemCost + totalGoodCost;

  return numberWithSpaces(totalCost.toFixed(0)) + " ";
}
