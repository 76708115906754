import React from "react";

const Delivery = () => {
  return (
    <div class="container mt-4">
      <h3 class="my-4">
        После получения заказа наши менеджеры сразу свяжутся с Вами и уточнят
        все детали.
      </h3>
      <h3>Курьерская доставка по Алматы</h3>
      <p>
        При оплате на сумму до 35 000 тенге стоимость доставки составляет 2 800
        тенге. <br />
        При оплате на сумму свыше 35 000 тенге доставка осуществляется
        бесплатно.
      </p>
      <h3>Отправка в регионы Казахстана:</h3>
      <p>
        Отправка товара производится в течение 24 часов после поступления оплаты
        заказа, с 10 до 18 в рабочие дни. <br />
        Стоимость доплаты за отправку товаров в регионы Казахстана определяется
        в зависимости от способа отправки заказа в регион и только по предоплате
        за счет клиента.
      </p>
    </div>
  );
};

export default Delivery;
