import React, {useContext, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Context} from "../index";
import {fetchHomeIndexBanner} from "../http/shop-api";
import {Navigation} from "swiper";

const HomeIndexBanner = () => {
    const {shopStore} = useContext(Context)

    useEffect(() => {
        fetchHomeIndexBanner().then((data) => {
            shopStore.setHomeIndexBanner(data)
        })
    }, [])


    return (
        <Swiper
            className={'home-index-banner'}
            modules={[Navigation]}
            spaceBetween={20}
            slidesPerView={1}
            loop={true}
            navigation={{
                nextEl: '.btn-next',
                prevEl: '.btn-prev'
            }}
        >
            {
                shopStore.homeIndexBanner.length > 0 ?
                    <>
                        {
                            shopStore.homeIndexBanner.map((m, index) => {
                                return <SwiperSlide key={index}>
                                    <a href={m.link}>
                                        <img src={process.env.PUBLIC_URL + m.path} width={'100%'}/>
                                    </a>
                                </SwiperSlide>
                            })
                        }
                    </>
                    :
                    <>
                    <SwiperSlide className='download'>
                                </SwiperSlide>
                    </>
            }
            <div className="slider-navigation">
                <button className="btn-prev">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-arrow-left" viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                              d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
                    </svg>
                </button>
                <button className="btn-next">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                         className="bi bi-arrow-right" viewBox="0 0 16 16">
                        <path fillRule="evenodd"
                              d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                </button>
            </div>
        </Swiper>
    );
};

export default observer(HomeIndexBanner);