import React from 'react';

const AboutUs = () => {
    return (
        <div class="container mt-4">
            <h2>UPACK.KZ</h2>
    <div class="row">
        <div class="col-md-6">
            <p class="about-text">
                Upack.kz более 20-ти лет занимается реализацией качественной одноразовой посуды и упаковки. За это время мы наработали базу лучших мировых производителей посуды и упаковки из пластика, алюминия, ВПС и других материалов. Все товары на сайте сертифицированы, экологически безопасны, изготавливаются из безвредных материалов и прекрасно подходят к любым пищевым продуктам.
            </p>
            <p class="about-text">
                Мы стараемся как можно чаще обновлять товар, чтобы удовлетворять потребности каждого нашего клиента.
            </p>
            <h4>Наши реквизиты</h4>
            <p class="about-text">
                г. Алматы, мкр. Мамыр, ул. Коржын, 1Б <br/>

                БИН 590730301484<br/>

                Талон о регистрационных данных индивидуального предпринимателя №KZ59TWQ00149916, выданный 16.06.2017 г. УГД по Ауэзовскому району  г. Алматы<br/>

                Свидетельство о постановке на регистрационный учет по НДС,<br/>

                серия 60001 №1194016 от 16.06.2017г.<br/>

                ИИК KZ486017131000076332 в АО Народный банк Казахстана,<br/>

                БИК HSBKKZKX<br/>
            </p>
        </div>
        <div class="col-md-6 text-center">
 
        </div>
    </div>

</div>
    );
}

export default AboutUs;
