import React from 'react';

const Return = () => {
    return (
        <div className='container pt-4'>
            <h2>Возврат и обмен товара</h2>
    <div class="alert alert-info">
        <h4>
            - Возврат и обмен товара производится только в соответствии с Законодательством Республики Казахстан.
            <br/>
            - Возврат и обмен товаров возможен в течение 14 дней после получения товара покупателем.
            <br/>
            - Обратная доставка товаров осуществляется за счет покупателя.
        </h4>
    </div>

    <p class="my-3">Ниже приведена выписка из закона:</p>

    <div class="card">
        <div class="card-header">
            О ЗАЩИТЕ ПРАВ ПОТРЕБИТЕЛЕЙ
        </div>
        <div class="card-body">
            <p>
                <b> Закон Республики Казахстан от 4 мая 2010 года № 274-IV</b> <br/>
                С изменениями, внесенными Законом РК от 21.04.2016 № 504-V.
            </p>
            <h5>
                Глава 1. ОБЩИЕ ПОЛОЖЕНИЯ
            </h5>
            <p>
                <b>Статья 1.</b> Основные понятия, используемые в настоящем Законе. <br/>
                В настоящем Законе используются следующие основные понятия
            </p>
            <p>
                <b>15{')'} потребитель </b>– физическое лицо, имеющее намерение заказать или приобрести либо заказывающее, приобретающее и (или) использующее товар (работу, услугу) исключительно для личного, семейного, домашнего или иного использования, не связанного с предпринимательской деятельностью;
            </p>
            <p>
                <b>Статья 14.</b> Право потребителей на обмен или возврат товара надлежащего качества
            </p>
            <p>
                Покупатель вправе в течение четырнадцати дней с момента передачи ему непродовольственного товара, если более длительный срок не объявлен продавцом (изготовителем), обменять купленный товар в месте покупки или иных местах, объявленных продавцом (изготовителем), на аналогичный товар другого размера, формы, габарита, фасона, расцветки, комплектации и тому подобное либо на другой товар по соглашению сторон, произведя в случае разницы в цене необходимый перерасчет с продавцом.
            </p>
            <p>
                Обмен производится в соответствии со статьей 30 настоящего Закона.
            </p>
            <p>
                При отсутствии необходимого для обмена товара у продавца (изготовителя) покупатель вправе возвратить приобретенный товар продавцу (изготовителю) и получить уплаченную за него денежную сумму.
            </p>
            <p>
                Местом обмена или возврата товара является место покупки товара, если иное не предусмотрено договором.
            </p>
            <p>
                <b>Статья 30.</b> Обязанности продавца (изготовителя) при продаже товара как надлежащего, так и ненадлежащего качества
            </p>
            <p>
                Продавец (изготовитель) обязан обеспечить обмен или возврат непродовольственного товара надлежащего качества, если он не был в употреблении, сохранены его товарный вид, потребительские свойства, пломбы, ярлыки, а также документ, подтверждающий факт приобретения товара, в течение четырнадцати календарных дней, если более длительный срок не установлен договором, со дня приобретения товара…
            </p>
            <p>
                В случае если документ, подтверждающий факт приобретения товара, был утерян или по каким-либо причинам не выдан потребителю, то обмен или возврат товара должен быть произведен, если потребитель докажет факт покупки у данного продавца (изготовителя).
            </p>
        </div>
    </div>

</div>
    );
}

export default Return;
